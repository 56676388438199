import theme from '@/theme/_generated';

import { SystemStyleFunction } from '@chakra-ui/theme-tools';

const defaultLink = theme.components.Link;

export const listOfColorSchemes = ['amica-purple', 'amica-light'];

const baseStyle = {
  ...defaultLink.baseStyle,
  _hover: {
    textDecoration: 'underline',
    _disabled: {
      textDecoration: 'none',
    },
  },
  _disabled: {
    opacity: 1,
    cursor: 'not-allowed',
    boxShadow: 'none',
  },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const variantNavigation: SystemStyleFunction = (props: any) => {
  const { colorScheme: c } = props;

  if (c.includes('amica-light')) {
    return {
      color: 'custom.neutral.100',
      _hover: {
        textDecoration: 'underline',
        color: 'custom.neutral.200',
        _disabled: {
          textDecoration: 'none',
        },
      },
      _disabled: {
        opacity: 1,
        cursor: 'not-allowed',
        boxShadow: 'none',
      },
    };
  } else {
    return {
      color: 'custom.primary.700',
      _hover: {
        textDecoration: 'underline',
        color: 'custom.primary.800',
        _disabled: {
          textDecoration: 'none',
        },
      },
      _disabled: {
        opacity: 1,
        cursor: 'not-allowed',
        boxShadow: 'none',
      },
    };
  }
};

const variants = {
  // ...defaultLink.variants,
  navigation: variantNavigation,
};

const button = {
  ...defaultLink,
  defaultProps: {
    colorScheme: 'amica-purple',
  },
  baseStyle,
  variants,
};

export default button;
