// Base off the default theme: https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components/button.ts

import theme from '@/theme/_generated';

import {
  getColor,
  mode,
  SystemStyleFunction,
  SystemStyleObject,
} from '@chakra-ui/theme-tools';

const defaultButton = theme.components.Button;

export const listOfColorSchemes = [
  'amica-purple',
  'amica-orange',
  'amica-grey',
  'amica-black',
  'amica-blue',
  'amica-green',
  'amica-destructive',
];

const baseStyle = {
  ...defaultButton.baseStyle,
  fontWeight: 600,
  _disabled: {
    opacity: 1,
    cursor: 'not-allowed',
    boxShadow: 'none',
  },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const variantGhost: SystemStyleFunction = (props: any) => {
  const { colorScheme: c } = props;

  if (c.includes('amica-purple')) {
    return {
      color: mode(`inherit`, `whiteAlpha.900`)(props),
      _hover: {
        bg: mode(`custom.primary.50`, `custom.primary.800`)(props),
      },
      _active: {
        bg: 'transparent',
        color: mode(`custom.primary.600`, `custom.primary.800`)(props),
      },
      _disabled: {
        color: getColor(theme, `custom.neutral.500`),
      },
    };
  }

  if (c.includes('amica-')) {
    return {
      color: mode(`inherit`, `whiteAlpha.900`)(props),
      _hover: {
        bg: mode(`custom.primary.50`, `whiteAlpha.200`)(props),
      },
      _active: {
        bg: 'transparent',
        color: mode(`custom.primary.600`, `whiteAlpha.200`)(props),
      },
      _disabled: {
        color: mode(`custom.neutral.500`, `whiteAlpha.200`)(props),
      },
    };
  }

  return defaultButton.variants.ghost(props);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const variantOutline: SystemStyleFunction = (props: any) => {
  const { colorScheme: c } = props;

  const disabled = {
    color: mode(`custom.neutral.500`, `whiteAlpha.200`)(props),
    borderColor: mode(`custom.neutral.300`, `whiteAlpha.200`)(props),
  };

  if (c === 'amica-purple') {
    return {
      border: '2px solid',
      borderColor: getColor(theme, `custom.primary.600`),
      ...variantGhost(props),
      _disabled: disabled,
    };
  }

  if (c === 'amica-orange') {
    return {
      border: '2px solid',
      borderColor: getColor(theme, `custom.accent.400`),
      ...variantGhost(props),
      _disabled: disabled,
    };
  }

  if (c === 'amica-grey') {
    return {
      border: '2px solid',
      borderColor: getColor(theme, `custom.neutral.200`),
      ...variantGhost(props),
      _disabled: disabled,
    };
  }

  if (c === 'amica-black') {
    return {
      border: '2px solid',
      borderColor: mode(`custom.neutral.600`, `white`)(props),
      ...variantGhost(props),
      _disabled: disabled,
    };
  }

  if (c === 'amica-destructive') {
    return {
      border: '2px solid',
      borderColor: getColor(theme, `custom.destructive.400`),
      ...variantGhost(props),
      _disabled: disabled,
    };
  }

  if (c === 'amica-blue') {
    return {
      border: '2px solid',
      borderColor: mode(`custom.supporting.400`, `whiteAlpha.200`)(props),
      ...variantGhost(props),
      _disabled: disabled,
    };
  }

  if (c === 'amica-green') {
    return {
      border: '2px solid',
      borderColor: getColor(theme, `custom.supporting.700`),
      ...variantGhost(props),
      _disabled: disabled,
    };
  }

  return defaultButton.variants.outline(props);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const variantSolid: SystemStyleFunction = (props) => {
  const { colorScheme: c } = props;

  const disabled = {
    bg: getColor(theme, `custom.neutral.200`),
    color: getColor(theme, `custom.neutral.500`),
    borderColor: getColor(theme, `custom.neutral.200`),
    _hover: {
      bg: getColor(theme, `custom.neutral.200`),
      color: getColor(theme, `custom.neutral.500`),
      borderColor: getColor(theme, `custom.neutral.200`),
    },
  };

  if (c === 'amica-purple') {
    const bg = getColor(theme, `custom.primary.700`);

    return {
      bg,
      border: '2px solid',
      borderColor: bg,
      color: 'white',
      _hover: {
        bg: mode(`custom.primary.800`, `custom.primary.50`)(props),
        color: mode(`white`, `custom.neutral.800`)(props),
      },
      _active: {
        boxShadow: 'outline',
      },
      _disabled: disabled,
    };
  }

  if (c === 'amica-orange') {
    const bg = getColor(theme, `custom.accent.400`);

    return {
      bg,
      border: '2px solid',
      borderColor: bg,
      color: getColor(theme, `custom.neutral.900`),
      _hover: {
        bg: getColor(theme, 'custom.accent.500'),
        borderColor: getColor(theme, 'custom.accent.500'),
      },
      _active: {
        boxShadow: 'outline',
      },
      _disabled: disabled,
    };
  }

  if (c === 'amica-grey') {
    const bg = getColor(theme, `custom.primary.50`);

    return {
      bg,
      border: '2px solid',
      borderColor: bg,
      color: getColor(theme, `custom.neutral.900`),
      _hover: {
        bg: getColor(theme, 'custom.primary.200'),
        borderColor: getColor(theme, 'custom.primary.200'),
      },
      _active: {
        boxShadow: 'outline',
      },
      _disabled: disabled,
    };
  }

  if (c === 'amica-black') {
    const bg = getColor(theme, `custom.neutral.700`);

    return {
      bg,
      border: '2px solid',
      borderColor: bg,
      color: 'white',
      _hover: {
        bg: mode(`custom.neutral.600`, `whiteAlpha.300`)(props),
        border: '2px solid',
        borderColor: getColor(theme, 'custom.neutral.900'),
      },
      _active: {
        boxShadow: 'outline',
      },
      _disabled: disabled,
    };
  }

  if (c === 'amica-blue') {
    const bg = mode(`custom.supporting.400`, `whiteAlpha.200`)(props);

    return {
      bg,
      border: '2px solid',
      borderColor: bg,
      _hover: {
        bg: mode(`custom.supporting.400`, `whiteAlpha.300`)(props),
      },
      _active: {
        boxShadow: 'outline',
      },
      _disabled: disabled,
    };
  }

  if (c === 'amica-green') {
    const bg = getColor(theme, `custom.supporting.700`);

    return {
      bg,
      border: '2px solid',
      borderColor: bg,
      _hover: {
        bg: getColor(theme, `custom.supporting.900`),
        borderColor: getColor(theme, `custom.supporting.900`),
        boxShadow: 'dark-lg',
      },
      _active: {
        boxShadow: 'outline',
      },
      _disabled: disabled,
    };
  }

  if (c === 'amica-destructive') {
    const bg = getColor(theme, `custom.destructive.500`);

    return {
      bg,
      border: '2px solid',
      borderColor: bg,
      color: getColor(theme, `custom.text.default`),
      _hover: {
        bg: getColor(theme, `custom.destructive.600`),
        border: '2px solid',
        borderColor: getColor(theme, 'custom.destructive.600'),
      },
      _active: {
        boxShadow: 'outline',
      },
      _disabled: disabled,
    };
  }

  return defaultButton.variants.solid(props);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const variantLink: SystemStyleFunction = (props) => {
  const { colorScheme: c } = props;
  if (c.includes('amica-')) {
    return {
      padding: 0,
      height: 'auto',
      lineHeight: 'normal',
      verticalAlign: 'baseline',
      color: mode(`${c}.500`, `white`)(props),
      _hover: {
        textDecoration: 'underline',
        _disabled: {
          textDecoration: 'none',
        },
      },
      _active: {
        color: mode(`${c}.700`, `${c}.500`)(props),
      },
    };
  }

  return defaultButton.variants.link(props);
};

const variants = {
  // ...defaultButton.variants,
  solid: variantSolid,
  outline: variantOutline,
  ghost: variantGhost,
  link: variantLink,
};

const sizes: Record<string, SystemStyleObject> = {
  big: {
    height: ['36px', '36px', '44px', '44px'],
    minW: ['73px', '81px', '103px', '119px'],
    px: [3, 4, 6, 8],
    textStyle: 'button',
  },
  small: {
    height: ['34px', '34px', '37px', '37px'],
    minW: ['67px', '75px', '81px', '97px'],
    px: [3, 4, 4, 6],
    textStyle: 'button 2',
  },
  cta: {
    height: '56px',
    minW: ['73px', '81px', '103px', '119px'],
    px: 6,
    textStyle: 'button',
    fontSize: '1.125rem',
  },
};

const button = {
  ...defaultButton,
  defaultProps: {
    colorScheme: 'amica-purple',
    size: 'small',
  },
  sizes,
  baseStyle,
  variants,
};

export default button;
