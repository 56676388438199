// AUTO-GENERATED - File generated by figma2theme 0.5.9, manual edits may be overwritten
// Figma file: https://www.figma.com/file/L0CbjoBPTPATw2jGavPusN
// File version selected: Latest changes to the file (last updated: 2021-12-02 @ 14:59)
// Generated at: Wed Dec 08 2021 16:58:20 GMT+1100 (Australian Eastern Daylight Time)

import { theme as defaultTheme } from '@chakra-ui/react';
import { createBreakpoints } from '@chakra-ui/theme-tools';

import * as customIcons from './icons';

/**
 * Foundations
 */

const breakpoints = createBreakpoints({
  xs: '22em',
  sm: '30em',
  md: '48em',
  lg: '62em',
  xl: '80em',
});

const colors = {
  /**
   * New colours introduced for 2024 website update.
   * Imported from amica Design System.
   */
  text: {
    default: '#21202C',
    subdued: '#52505F',
    disabled: '#ACABBB',
  },
  /**
   * Original text styles
   */
  neutral: {
    '25': '#F7F7FC',
    '50': '#F0F2F5',
    '100': '#EAEAF0',
    '200': '#D0D0DA',
    '300': '#ACABBB',
    '400': '#7B798E',
    '500': '#5B596B',
    '600': '#41404B',
    '700': '#2B2A36',
    '800': '#21202C',
    '900': '#181823',
  },
  primary: {
    '50': '#E4E4F0',
    '100': '#DEDEF5',
    '200': '#CCC7F5',
    '300': '#B8B3E7',
    '400': '#B1AAFF',
    '500': '#847BDD',
    '600': '#665CD0',
    '700': '#393189',
    '800': '#27235B',
    '900': '#16133D',
  },
  supporting: {
    '50': '#D7F6F9',
    '100': '#C2E7EA',
    '200': '#92D2D6',
    '300': '#74BABE',
    '400': '#26B0B8',
    '500': '#108F97',
    '600': '#03747B',
    '700': '#036268',
    '800': '#004246',
    '900': '#023235',
  },
  accent: {
    '100': '#FFFBF8',
    '200': '#F5F2EC',
    '300': '#F5DBC2',
    '400': '#F1BF91',
    '500': '#DFAA79',
    '800': '#B26B2A',
    '900': '#925217',
  },
  /**
   * Destructive colours updated for 2024 website update.
   */
  destructive: { '500': '#F5DBC2', '600': '#DB995D' },
};
export type Colors = typeof colors;

const radii = {
  none: '0',
  sm: '0.125rem',
  md: '0.25rem',
  lg: '0.5rem',
  xl: '1rem',
  full: '9999px',
};
export type Radii = typeof radii;

const shadows = {
  /**
   * New shadows introduced for 2024 website update.
   * Imported from amica Design System.
   */
  layer: '8px 8px 24px 0px rgba(30, 26, 78, 0.16)',
  card: '4px 4px 24px 0px rgba(33, 32, 44, 0.03)',
  /**
   * Original text styles
   */
  sm: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
  md: '0 4px 6px -1px rgba(0, 0, 0, 0.03), 0 2px 4px -1px rgba(0, 0, 0, 0.03)',
  lg: '0 10px 15px -3px rgba(0, 0, 0, 0.03), 0 4px 6px -2px rgba(0, 0, 0, 0.02)',
  'dark-lg':
    '0 0 0 1px rgba(0, 0, 0, 0.06), 0 5px 10px 0 rgba(0, 0, 0, 0.06), 0 15px 40px 0 rgba(0, 0, 0, 0.06)',
  xl: '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
  '2xl': '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
  outline: '0 0 0 3px rgba(66, 153, 225, 0.6)',
  inner: 'inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)',
  none: 'none',
};
export type Shadows = typeof shadows;

const spaces = {
  '0': '0',
  '1': '0.25rem',
  '2': '0.5rem',
  '3': '0.75rem',
  '4': '1rem',
  '5': '1.25rem',
  '6': '1.5rem',
  '8': '2rem',
  '10': '2.5rem',
  '12': '3rem',
  '14': '3.5rem',
  '16': '4rem',
  '18': '4.5rem',
  '20': '5rem',
  '24': '6rem',
  '32': '8rem',
  '40': '10rem',
  '48': '12rem',
  '56': '14rem',
  '64': '16rem',
  px: '1px',
};
export type Spaces = typeof spaces;

const sizes = {
  ...spaces,
  ...{
    full: '100%',
    '3xs': '14rem',
    '2xs': '16rem',
    xs: '20rem',
    sm: '24rem',
    md: '28rem',
    lg: '32rem',
    xl: '36rem',
    '2xl': '42rem',
    '3xl': '48rem',
    '4xl': '56rem',
    '5xl': '64rem',
    '6xl': '72rem',
  },
  container: {
    sm: '640px',
    md: '768px',
    lg: '1024px',
    xl: '1280px',
  },
};
export type Sizes = typeof sizes;

const typography = {
  letterSpacings: {
    tighter: '-0.05em',
    tight: '-0.025em',
    normal: '0',
    wide: '0.025em',
    wider: '0.05em',
    widest: '0.1em',
  },
  lineHeights: {
    '3': '0.75rem',
    '4': '1rem',
    '5': '1.25rem',
    '6': '1.5rem',
    '7': '1.75rem',
    '8': '2rem',
    '9': '2.25rem',
    '10': '2.5rem',
    '11': '2.75rem',
    '12': '3rem',
    '13': '3.25rem',
    '14': '3.5rem',
    '15': '3.75rem',
    '16': '4rem',
    '17': '4.25rem',
    '18': '4.5rem',
    '19': '4.75rem',
    '20': '5rem',
    normal: 'normal',
    none: '1',
    shorter: '1.25',
    short: '1.375',
    base: '1.5',
    tall: '1.625',
    taller: '2',
  },
  fontWeights: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900,
  },
  fonts: {
    heading: '"Noto Serif", sans-serif',
    body: '"SF UI Display", sans-serif',
    mono: '"Courier New", Courier, monospace',
  },
  fontSizes: {
    xs: '0.8331249952316284rem',
    sm: '1rem',
    md: '1.2000000476837158rem',
    lg: '1.440000057220459rem',
    xl: '1.728124976158142rem',
    '2xl': '2.0737500190734863rem',
    '3xl': '2.4881250858306885rem',
    '4xl': '2.9862499237060547rem',
    '5xl': '3.583125114440918rem',
    '6xl': '4.300000190734863rem',
  },
};
export type Typography = typeof typography;

/**
 * Text Styles
 */

const textStyles = {
  /**
   * New text styles introduced for 2024 website update.
   */
  'Website/Display': {
    fontFamily: 'Founders Grotesk',
    fontSize: { base: '3.8125rem', md: '5.375rem' },
    fontStyle: 'normal',
    fontWeight: '500',
    letterSpacing: '0em',
    lineHeight: '1',
    textDecorationLine: 'none',
    textTransform: 'none',
  },
  'Website/h1': {
    fontFamily: 'Founders Grotesk',
    fontSize: { base: '3.8125rem', xl: '4.25rem' },
    fontStyle: 'normal',
    fontWeight: '500',
    letterSpacing: '0em',
    lineHeight: '1',
    textDecorationLine: 'none',
    textTransform: 'none',
  },
  'Website/h2': {
    fontFamily: 'Founders Grotesk',
    fontSize: { base: '3rem', xl: '3.375rem' },
    fontStyle: 'normal',
    fontWeight: '500',
    letterSpacing: '0em',
    lineHeight: '1',
    textDecorationLine: 'none',
    textTransform: 'none',
  },
  'Website/h3': {
    fontFamily: 'Founders Grotesk',
    fontSize: { base: '2.4375rem', xl: '2.75rem' },
    fontStyle: 'normal',
    fontWeight: '500',
    letterSpacing: '0em',
    lineHeight: '1',
    textDecorationLine: 'none',
    textTransform: 'none',
  },
  'Website/h4': {
    fontFamily: 'Founders Grotesk',
    fontSize: { base: '1.9375rem', xl: '2.1875rem' },
    fontStyle: 'normal',
    fontWeight: '500',
    letterSpacing: '0em',
    lineHeight: '1',
    textDecorationLine: 'none',
    textTransform: 'none',
  },
  'Website/h5': {
    fontFamily: 'Founders Grotesk',
    fontSize: { base: '1.5625rem', xl: '1.75rem' },
    fontStyle: 'normal',
    fontWeight: '500',
    letterSpacing: '0em',
    lineHeight: '1',
    textDecorationLine: 'none',
    textTransform: 'none',
  },
  'Website/h6': {
    fontFamily: 'Founders Grotesk',
    fontSize: { base: '1.25rem', xl: '1.375rem' },
    fontStyle: 'normal',
    fontWeight: '500',
    letterSpacing: '0em',
    lineHeight: '1',
    textDecorationLine: 'none',
    textTransform: 'none',
  },
  'Website/paragraph': {
    fontFamily: 'SF UI Display',
    fontSize: { xl: '1.125rem', sm: '1rem' },
    fontStyle: 'normal',
    fontWeight: '400',
    letterSpacing: '0.01em',
    lineHeight: { xl: '1.37', sm: '1.15' },
    textDecorationLine: 'none',
    textTransform: 'none',
  },
  'Website/paragraph-small': {
    fontFamily: 'SF UI Display',
    fontSize: '0.9375rem',
    fontStyle: 'normal',
    fontWeight: '400',
    letterSpacing: '0.01em',
    lineHeight: '1.15',
    textDecorationLine: 'none',
    textTransform: 'none',
  },
  'Website/paragraph-bold': {
    fontFamily: 'SF UI Display',
    fontSize: { xl: '1.125rem', sm: '1rem' },
    fontStyle: 'normal',
    fontWeight: '600',
    letterSpacing: '0.01em',
    lineHeight: { xl: '1.37', sm: '1.15' },
    textDecorationLine: 'none',
    textTransform: 'none',
  },
  'Website/paragraph-small-bold': {
    fontFamily: 'SF UI Display',
    fontSize: '0.9375rem',
    fontStyle: 'normal',
    fontWeight: '600',
    letterSpacing: '0.01em',
    lineHeight: '1.15',
    textDecorationLine: 'none',
    textTransform: 'none',
  },
  /**
   * Original text styles
   */
  tite: {
    fontFamily: 'Founders Grotesk',
    fontSize: {
      base: '2.0737500190734863rem',
      sm: '2.4881250858306885rem',
      md: '2.9862499237060547rem',
      lg: '3.583125114440918rem',
      xl: '4.300000190734863rem',
    },
    fontStyle: 'normal',
    fontWeight: '500',
    letterSpacing: '0em',
    lineHeight: {
      base: '2.375rem',
      sm: '2.75rem',
      md: '3.125rem',
      lg: '3.75rem',
      xl: '4.375rem',
    },
    textDecorationLine: 'none',
    textTransform: 'none',
  },
  'h1 conversational': {
    fontFamily: 'Founders Grotesk',
    fontSize: {
      base: '1.728124976158142rem',
      sm: '2.0737500190734863rem',
      md: '2.4881250858306885rem',
      lg: '2.9862499237060547rem',
      xl: '3.583125114440918rem',
    },
    fontStyle: 'normal',
    fontWeight: '500',
    letterSpacing: '0em',
    lineHeight: {
      base: '2rem',
      sm: '2.125rem',
      md: '2.625rem',
      lg: '3.125rem',
      xl: '3.6875rem',
    },
    textDecorationLine: 'none',
    textTransform: 'none',
  },
  'h2 conversational': {
    fontFamily: 'Founders Grotesk',
    fontSize: {
      base: '1.5rem',
      sm: '1.5rem',
      md: '1.625rem',
      lg: '1.8125rem',
      xl: '1.875rem',
    },
    fontStyle: 'normal',
    fontWeight: '500',
    letterSpacing: '0em',
    lineHeight: {
      base: '1.625rem',
      sm: '1.625rem',
      md: '1.75rem',
      lg: '2rem',
      xl: '2.125rem',
    },
    textDecorationLine: 'none',
    textTransform: 'none',
  },
  h1: {
    fontFamily: 'SF UI Display',
    fontSize: {
      base: '2.0737500190734863rem',
      sm: '2.375rem',
      md: '2.5rem',
      lg: '2.75rem',
      xl: '3rem',
    },
    fontStyle: 'normal',
    fontWeight: '700',
    letterSpacing: '0em',
    lineHeight: {
      base: '2.75rem',
      sm: '3rem',
      md: '3.125rem',
      lg: '3.375rem',
      xl: '3.5rem',
    },
    textDecorationLine: 'none',
    textTransform: 'none',
  },
  h2: {
    fontFamily: 'SF UI Display',
    fontSize: {
      base: '1.728124976158142rem',
      sm: '1.75rem',
      md: '1.875rem',
      lg: '2.125rem',
      xl: '2.5rem',
    },
    fontStyle: 'normal',
    fontWeight: '700',
    letterSpacing: '0em',
    lineHeight: {
      base: '2.25rem',
      sm: '2.375rem',
      md: '2.375rem',
      lg: '2.75rem',
      xl: '3rem',
    },
    textDecorationLine: 'none',
    textTransform: 'none',
  },
  h3: {
    fontFamily: 'SF UI Display',
    fontSize: {
      base: '1.1875rem',
      sm: '1.375rem',
      md: '1.5rem',
      lg: '1.75rem',
      xl: '2rem',
    },
    fontStyle: 'normal',
    fontWeight: '700',
    letterSpacing: '0em',
    lineHeight: {
      base: '1.4375rem',
      sm: '1.625rem',
      md: '1.75rem',
      lg: '2.125rem',
      xl: '2.5rem',
    },
    textDecorationLine: 'none',
    textTransform: 'none',
  },
  h4: {
    fontFamily: 'SF UI Display',
    fontSize: {
      base: '1.1875rem',
      sm: '1.1875rem',
      md: '1.25rem',
      lg: '1.375rem',
      xl: '1.5rem',
    },
    fontStyle: 'normal',
    fontWeight: '700',
    letterSpacing: '0em',
    lineHeight: {
      base: '1.5625rem',
      sm: '1.5rem',
      md: '1.5625rem',
      lg: '1.625rem',
      xl: '1.875rem',
    },
    textDecorationLine: 'none',
    textTransform: 'none',
  },
  'body 1': {
    fontFamily: 'SF UI Display',
    fontSize: { base: '1.0625rem', xl: '1.125rem' },
    fontStyle: 'normal',
    fontWeight: '400',
    letterSpacing: '0em',
    lineHeight: { base: '1.375rem', xl: '1.4375rem' },
    textDecorationLine: 'none',
    textTransform: 'none',
  },
  'body 1 bold': {
    fontFamily: 'SF UI Display',
    fontSize: { base: '1.0625rem', xl: '1.125rem' },
    fontStyle: 'normal',
    fontWeight: '700',
    letterSpacing: '0em',
    lineHeight: { base: '1.3125rem', xl: '1.4375rem' },
    textDecorationLine: 'none',
    textTransform: 'none',
  },
  'body 2': {
    fontFamily: 'SF UI Display',
    fontSize: { base: '0.9375rem', xl: '1rem' },
    fontStyle: 'normal',
    fontWeight: '400',
    letterSpacing: '0em',
    lineHeight: { base: '1.1875rem', xl: '1.375rem' },
    textDecorationLine: 'none',
    textTransform: 'none',
  },
  quote: {
    fontFamily: 'SF UI Display',
    fontSize: { base: '1.2000000476837158rem', xl: '1.440000057220459rem' },
    fontStyle: 'normal',
    fontWeight: '400',
    letterSpacing: '0em',
    lineHeight: { base: '1.75rem', xl: '2rem' },
    textDecorationLine: 'none',
    textTransform: 'none',
  },
  button: {
    fontFamily: 'SF UI Display',
    fontSize: { base: '1rem', xl: '1.125rem' },
    fontStyle: 'normal',
    fontWeight: '600',
    letterSpacing: '0em',
    lineHeight: { base: '1.25rem', xl: '1.5rem' },
    textDecorationLine: 'none',
    textTransform: 'none',
  },
  'button 2': {
    fontFamily: 'SF UI Display',
    fontSize: { base: '0.875rem', xl: '0.9375rem' },
    fontStyle: 'normal',
    fontWeight: '600',
    letterSpacing: { base: '0em', xl: '0.02em' },
    lineHeight: { base: '1.125rem', xl: '1.3125rem' },
    textDecorationLine: 'none',
    textTransform: 'none',
  },
  link: {
    fontFamily: 'SF UI Display',
    fontSize: { base: '1rem', xl: '1.125rem' },
    fontStyle: 'normal',
    fontWeight: '600',
    letterSpacing: '0em',
    lineHeight: '1.5rem',
    textDecorationLine: 'underline',
    textTransform: 'none',
  },
  label: {
    fontFamily: 'SF UI Display',
    fontSize: { base: '0.8331249952316284rem', xl: '1rem' },
    fontStyle: 'normal',
    fontWeight: '500',
    letterSpacing: '0.025em',
    lineHeight: { base: '1rem', xl: '1.25rem' },
    textDecorationLine: 'none',
    textTransform: 'none',
  },
};
export type TextStyles = typeof textStyles;

/**
 * Theme
 */

const theme = {
  ...defaultTheme,
  breakpoints: breakpoints,
  colors: {
    ...defaultTheme.colors,
    custom: colors,
  },
  radii: radii,
  shadows: shadows,
  space: spaces,
  sizes: sizes,
  fonts: typography.fonts,
  fontSizes: typography.fontSizes,
  fontWeights: typography.fontWeights,
  lineHeights: typography.lineHeights,
  letterSpacings: typography.letterSpacings,
  textStyles: textStyles,
  customIcons: customIcons, // this is here purely to be read by `Foundation.stories.tsx`
};
export type Theme = typeof theme;

export default theme;
