import { Global } from '@emotion/react';
const Fonts = () => (
  <Global
    styles={`
      @font-face {
        font-family: 'SF UI Display';
        font-style: normal;
        font-weight: normal;
        src: local('SF UI Display'),
          url('/fonts/SF-UI-Display-Regular.otf') format('opentype');
      }

      @font-face {
        font-family: 'SF UI Display';
        font-style: normal;
        font-weight: 600;
        src: local('SF UI Display'),
          url('/fonts/SF-UI-Display-Semibold.otf') format('opentype');
      }

      @font-face {
        font-family: 'SF UI Display';
        font-style: normal;
        font-weight: 700;
        src: local('SF UI Display'),
          url('/fonts/SF-UI-Display-Bold.otf') format('opentype');
      }

      @font-face {
        font-family: 'Founders Grotesk';
        font-style: normal;
        font-weight: normal;
        src: local('Founders Grotesk'),
          url('/fonts/FoundersGrotesk-Medium.otf') format('opentype');
      }
      `}
  />
);
export default Fonts;
