import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';

import theme from '@/theme';
import Fonts from './Fonts';

const AppProvider: React.FC = ({ children }) => {
  return (
    <ChakraProvider theme={theme}>
      <>
        <Fonts />
        {children}
      </>
    </ChakraProvider>
  );
};

export default AppProvider;
