import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import type { AppProps } from 'next/app';

import AppProvider from '@/components/AppProvider';

const App = ({ Component, pageProps, err }: AppProps & { err: Error }) => {
  useEffect(() => {
    if (process.env.NEXT_PUBLIC_GTM_CODE) {
      TagManager.initialize({
        gtmId: process.env.NEXT_PUBLIC_GTM_CODE as string,
      });
    }
  }, []);

  return (
    <AppProvider>
      {/* Workaround for https://github.com/zeit/next.js/issues/8592 */}
      <Component {...pageProps} err={err} />
    </AppProvider>
  );
};

export default App;
