// AUTO-GENERATED - File generated by figma2theme 0.5.9, manual edits may be overwritten
// Figma file: https://www.figma.com/file/L0CbjoBPTPATw2jGavPusN
// File version selected: Latest changes to the file (last updated: 2021-12-02 @ 14:59)
// Generated at: Wed Dec 08 2021 16:58:20 GMT+1100 (Australian Eastern Daylight Time)

import React from 'react';
import { createIcon } from '@chakra-ui/icon';

export const CircleDashIcon = createIcon({
  displayName: 'CircleDashIcon',
  viewBox: '0 0 24 24',
  path: (
    <>
      <path
        d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10Z"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M14 12h-4"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </>
  ),
});

export const OpenBoxIcon = createIcon({
  displayName: 'OpenBoxIcon',
  viewBox: '0 0 24 24',
  path: (
    <>
      <path
        d="M5 19H4a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </>
  ),
});

export const ZigZagIcon = createIcon({
  displayName: 'ZigZagIcon',
  viewBox: '0 0 24 24',
  path: (
    <>
      <path
        d="m12 18-9-3 18-6-9-3"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </>
  ),
});
