import { extendTheme, withDefaultProps } from '@chakra-ui/react';
import theme from '@/theme/_generated';

import { button, link } from './components';
import styles from './styles';

import bannerFullWidthOrangeBackground from '@/images/banner-fullwidth-orange.svg';
import bannerFullWidthGreyBackground from '@/images/banner-fullwidth-grey.svg';
import bannerFullWidthPurpleBackground from '@/images/banner-fullwidth-purple.svg';
import homePurpleLeftSvg from '@/images/home-purple-left.svg';

const basePadding = 16;
const basePaddingLg = 24;
const sectionBorder = {
  borderBottom: '1px',
  borderBottomColor: {
    base: 'custom.neutral.300',
    md: 'custom.neutral.100',
  },
};
// https://chakra-ui.com/docs/theming/customize-theme
const customTheme = extendTheme(
  // Override the global styles with our own
  {
    styles: styles,
    sizes: {
      // Used for desktop content maxwdith size
      content: {
        sm: '380px',
        md: '600px',
        lg: '700px',
      },
    },
    breakpoints: {
      prexl: '82em',
    },
    layerStyles: {
      header: {
        infoHeader: {
          paddingY: 1,
        },
        navigation: {
          paddingY: { base: 4, xl: 6 },
        },
      },
      footer: {
        paddingY: 8,
      },
      bannerBottom: {
        paddingTop: { base: 12, lg: 24 },
        paddingBottom: { base: 16, md: 16, lg: '107px' },
        position: 'relative',
        _after: {
          content: { xl: '""' },
          backgroundImage: {
            base: undefined,
            lg: `url(${bannerFullWidthOrangeBackground.src})`,
          },
          display: 'block',
          width: '368px',
          height: '160px',
          backgroundRepeat: 'no-repeat',
          position: 'absolute',
          bottom: 0,
          right: 0,
        },
      },
      standard: {
        grey: {
          page: {
            color: 'custom.neutral.900',
            backgroundColor: 'custom.neutral.25',
          },
          content: {
            paddingTop: { base: 16, md: 24, lg: 32 },
            paddingBottom: { base: 16, md: 24, lg: 32 },
          },
          outerImageBackground: {
            _after: {
              content: { xl: '""' },
              zIndex: -1,
              backgroundImage: {
                base: undefined,
                lg: `url(${bannerFullWidthGreyBackground.src})`,
              },
              display: 'block',
              width: '340px',
              height: '420px',
              backgroundRepeat: 'no-repeat',
              position: 'absolute',
              top: '-100px',
              right: '-70px',
            },
          },
        },
        dark: {
          page: {
            color: 'custom.neutral.900',
            backgroundColor: 'custom.neutral.25',
          },
          content: {
            paddingTop: { base: basePadding, xl: 32 },
            paddingBottom: { base: basePadding, xl: '186px' },
          },
        },
        purple: {
          page: {
            color: 'custom.neutral.900',
            backgroundColor: 'custom.neutral.25',
          },
          content: {
            paddingTop: { base: 16, md: 24, lg: 40 },
            paddingBottom: { base: 16, md: 24, lg: 40 },
          },
          outerImageBackground: {
            _after: {
              content: { xl: '""' },
              backgroundImage: {
                base: undefined,
                lg: `url(${bannerFullWidthPurpleBackground.src})`,
              },
              display: 'block',
              width: '300px',
              height: '616px',
              backgroundRepeat: 'no-repeat',
              position: 'absolute',
              top: 0,
              right: 0,
            },
          },
        },
        orange: {
          page: {
            backgroundColor: 'custom.accent.200',
          },
          content: {
            paddingTop: { base: 16, md: 24, lg: '72px' },
            paddingBottom: { base: 16, md: 24, lg: 40 },
          },
          outerImageBackground: {
            _after: {
              content: { md: '""' },
              backgroundImage: {
                base: undefined,
                lg: `url(${bannerFullWidthOrangeBackground.src})`,
              },
              display: 'block',
              width: '368px',
              height: '316px',
              backgroundRepeat: 'no-repeat',
              position: 'absolute',
              top: '100px',
              right: 0,
            },
          },
        },
      },
      home: {
        0: {
          outer: {
            backgroundColor: 'custom.neutral.25',
            paddingTop: { base: basePadding, lg: basePaddingLg },
            overflow: 'hidden',
            position: 'relative',
          },
          inner: {
            '& > div': {
              py: { base: 0, md: basePadding },
            },
            '& > div:not(:first-child)': {
              py: { base: basePadding },
              ...sectionBorder,
            },
          },
        },
        1: {
          outer: {
            backgroundColor: 'custom.neutral.25',
            overflow: 'hidden',
            position: 'relative',
          },
          inner: {
            '& > div': {
              py: { base: basePadding, lg: basePaddingLg },
              ...sectionBorder,
            },
          },
        },
        2: {
          outer: {
            backgroundColor: 'custom.neutral.25',
            overflow: 'hidden',
            position: 'relative',
          },
          inner: {
            '& > div': {
              py: { base: basePadding, lg: basePaddingLg },
              ...sectionBorder,
            },
          },
        },
        3: {
          outer: {
            backgroundColor: 'custom.neutral.25',
            paddingY: { base: basePadding, lg: basePaddingLg },
            overflow: 'hidden',
            position: 'relative',
          },
          inner: {
            '& > div:not(:last-child)': {
              marginBottom: { base: basePadding, lg: basePaddingLg },
            },
          },
        },
        4: {
          outer: {
            backgroundColor: 'custom.accent.200',
            paddingTop: { base: basePadding, lg: basePaddingLg },
            overflow: 'hidden',
            position: 'relative',
          },
          inner: {
            '& > div': {
              marginBottom: { base: basePadding, lg: basePaddingLg },
            },
          },
        },
      },
    },
  },
  // Override the button theme with our own
  {
    components: {
      Button: button,
      Link: link,
      Container: {
        baseStyle: {
          paddingX: {
            base: 4,
            md: 6,
            lg: 16,
            xl: 24,
            prexl: 0,
          },
        },
      },
    },
  },
  // Remove the default value for the Heading component "size" prop
  // This fixes the "textStyle" prop sometimes being overriden by the default size
  withDefaultProps({
    defaultProps: {
      size: '',
    },
    components: ['Heading'],
  }),
  theme
);
export type Theme = typeof customTheme;

export default customTheme;
